/* All modals should appear in front of popups */
 
.duplicateTinPopup.ui.popup {
    z-index: 1001 !important;
    position: absolute !important;
    transform: translate3d(533px, 370px, 0px) !important;
}
 
.ui.popup {
    z-index: 500 !important;
}
 
.ui.modal {
    z-index: 9999 !important;
}
 
.headerContainer {
    z-index: 100;
    background-color: #F9FAFB;
    padding-bottom: 1.7em !important;
}
 
.breadcrumbStickyContainer {
    margin-top: 4px;
    background-color: rgba(249, 250, 251, 1) !important;;
    top: 0;
    position: sticky;
    z-index: 3;
}
.reviewCardsStickyContainer {
    top: 56px;
    position: sticky;
    z-index: 3;
}
/* Renewals breadcrumb has checkmark above pages, increased height needed to displace review cards */
.reviewCardsStickyContainer.renewals {
    top: 50px;
}
@media screen and (max-width: 1400px) {
    .reviewCardsStickyContainer {
        top: 54px;
    }
}
@media screen and (max-width: 1044px) {
    .reviewCardsStickyContainer {
        top: 40px;
    }
}
@media screen and (min-width: 1400px) {
    /* Renewals breadcrumb has checkmark above pages, increased height needed to displace review cards */
    .reviewCardsStickyContainer.renewals {
        top: 56px;
    }
}
 
.margin-top-5 {
    margin-top: 5px !important;
}
 
.font-size-20 {
    font-size: 20px;
}
 
.font-size-16 {
    font-size: 16px !important;
}
 
.font-size-13 {
    font-size: 13px;
}
 
.font-size-12 {
    font-size: 12px;
}
 
.font-size-11 {
    font-size: 11px;
}
 
.font-size-10 {
    font-size: 10px;
}
 
.bold {
    font-weight: 700;
}
 
.width50Percent {
    width: 60% !important;
}
 
.width40Percent {
    width: 40% !important;
}
 
.overflow-wrap {
    word-wrap: break-word;
    padding-right: 10px
}
 
 
/* Applies to homepage carousel item description */
 
@media screen and (max-width: 900px) {
    .flexWrap900px {
        display: flex;
        flex-direction: column !important;
        align-items: center;
    }
    /* Applies to homepage carousel item description */
    .increaseWidth900px {
        width: 80% !important;
    }
}
 
.topRightButtons {
    float: right;
    margin-right: 15px !important;
    margin-top: 12px !important;
}
 
.headerMenu {
    background-color: white;
    display: flex;
    justify-content: space-between;
    padding: 8px;
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.4);
}
 
.navigationButtons .item:hover {
    /* Psuedo elements cannot use css vars and must be hardcoded */
    /* See https://caniuse.com/#search=css%20var */
    color: #002677 !important;
}
 
.navigationButtons .active {
    font-weight: 700 !important;
    border-bottom: 4px solid var(--color-uhc-bright-blue) !important;
    background-color: white !important;
    border-radius: 0% !important;
    color: var(--color-uhc-blue) !important;
}
 
.navigationButtons .active:hover {
    text-decoration: none !important;
}
 
.logo {
    min-width: 150px !important;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
}
 
.logo:hover {
    cursor: pointer !important;
}
 
.smallIcon {
    width: 150px;
    padding-top: 8px;
    height: 30px;
}
 
.bigIcon {
    width: 250px;
    height: 50px;
}
 
.pageDropdown {
    background-color: black;
}
 
.appContentContainer {
    background-color: rgba(249, 250, 251, 1) !important;
    min-height: calc(90vh - 168px);
}
 
.ui.segment.appContentContainer {
    border: none;
    box-shadow: none;
    margin-bottom: 0 !important;
    padding-bottom: 0px !important;
}
 
.bottomFooter {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
}
 
.footerPadding {
    padding-bottom: 20px !important;
}
 
.footerText {
    padding-top: 4px;
    padding-right: 10px;
    padding-left: 10px;
}
 
.footerTextSize {
    font-family: var(--fontFamily-Regular) !important;
    color: var(--color-uhc-light-gray) !important;
    line-height: 20px !important;
    font-size: 10px !important;
    height: 20px !important;
}
 
.footerTextSize:hover {
    font-size: 20px !important;
}
 
.copyRightText {
    padding-left: 450px !important;
    font-family: var(--fontFamily-Regular) !important;
    color: var(--color-uhc-light-gray) !important;
    line-height: 20px !important;
    font-size: 10px !important;
    height: 20px !important;
}
 
.hamburgerContainer {
    width: 0px;
    height: 100%;
    position: fixed;
    z-index: 4;
    top: 69px;
    left: 0;
    background-color: white;
    border-right: 1px solid lightgray;
    transition: 0.5s;
    overflow-x: hidden;
}
 
.hamburgerMenu {
    height: auto !important;
}
 
.hamburgerButton {
    padding-right: 0px !important;
}
 
.mobileContent {
    height: 0px;
    visibility: hidden;
}
 
.dropdownContent {
    background-color: rgb(243, 244, 245) !important;
}
 
.dropdownContent:hover {
    background-color: lightgray !important;
}
 
.hamburgerHeader {
    font-size: 15px !important;
}
 
.noTopMargin {
    margin-top: 0px !important;
}
 
.closeIcon {
    margin-right: 0px !important;
    margin-left: 0px !important;
    color: var(--color-uhc-blue);
}
 
.activeItem {
    font-weight: bold !important;
}
 
.lightbox {
    visibility: hidden;
    position: fixed;
    z-index: 3;
    width: 100%;
    height: 100%;
    text-align: center;
    top: 69px;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
}
 
#breadCrumb {
    z-index: 1;
}
 
.breadCrumbDivider {
    color: var(--color-uhc-blue) !important;
}
 
.ui.breadcrumb a.active {
    font-weight: 600 !important;
}
 
.ui.breadcrumb a.active {
    font-weight: 600 !important;
}
 
.active .stepperLabel {
    border: 2px solid var(--color-uhc-bright-blue) !important;
    border-radius: 50% !important;
}
 
.completed.stepperLabel {
    background-color: var(--color-uhc-blue) !important;
    padding-left: 9px !important;
    padding-right: 0 !important;
}
 
.stepperLabel {
    margin-right: .5em !important;
    border-radius: 50% !important;
    font-weight: 800 !important;
}
 
.ui.breadcrumb .divider {
    margin: 0 .5rem 0!important;
}
 
@media all and (max-width: 500px) {
    .headerMenu {
        height: 25px;
    }
}
 
.react-datepicker-wrapper,
.react-datepicker__input-container {
    display: block !important;
}
 
.hide {
    display: none !important;
}
 
.show {
    display: inherit;
}
 
.bottomPagination {
    width: 100%;
    text-align: center;
}
 
.theader .item {
    width: 50%;
    padding-left: 23% !important;
    font-weight: bold !important;
}
 
.searchbox input {
    width: 123px;
    font-size: 10px;
}
 
.darkBlue {
    color: var(--color-uhc-blue);
}
 
.darkBlueBanner {
    box-shadow: 0 1px 2px 0 rgba(34, 36, 38, .15);
    border-radius: .28571429rem;
    border: 1px solid rgba(34, 36, 38, .15);
    padding: 1em;
}
 
.inline {
    display: inline;
}
 
.inlineFlex {
    display: inline-flex;
}
 
@import '~antd/dist/antd.css';
.compareBar {
    display: flex;
    width: 100%;
    height: 65px;
    position: fixed;
    z-index: 3;
    bottom: 0;
    left: 0;
    background-color: white;
    border-top: 1px solid lightgray;
    justify-content: space-between;
    padding-left: 1rem;
    padding-right: 1rem;
    box-shadow: 2px 3px 8px 3px lightgray;
}
 
.flexContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}
 
.alignFlexStart {
    display: flex;
    align-items: flex-start;
}
 
.centerAlign {
    text-align: center;
}
 
.rightAlign {
    text-align: right;
}
 
 
/* * * * * * Margin utils * * * * * */
 
 
/* Same bottom margin as header elements */
 
.headerBottomMargin {
    margin-bottom: 14px;
}
 
.marginTop10 {
    margin-top: 10px !important;
}
 
.marginTop20 {
    margin-top: 20px;
}
 
.marginBottom10 {
    margin-bottom: 10px;
}
 
.marginBottom20 {
    margin-bottom: 20px !important;
}
 
.marginTop30 {
    margin-top: 30px;
}
 
.headerRowBottom.ui.grid>.row {
    padding-top: 5px !important;
}
 
.headerRowBottom {
    margin-top: 0px !important;
}
 
.headerRowBottom .row {
    padding-bottom: 8px !important;
}
 
.termsFooter {
    margin-bottom: 70px !important;
}
 
.sidebarWrapper {
    flex: 0;
    margin-top: 85px;
    margin-left: 10px;
    margin-right: 5px;
}
 
.contentWrapper {
    flex: 3;
}
 
.mainButton {
    font-size: 18px !important;
    width: 20% !important;
    min-height: 80px !important;
    margin-left: 5px !important;
    margin-right: 10px !important;
    margin-top: 50px !important;
    border-radius: 4px !important;
}
 
.welcomeMessage {
    width: 340px;
}
 
#recentActivity {
    overflow-y: scroll;
    max-height: 70vh;
    display: block;
    min-width: 860px !important;
}
 
.nbTable {
    overflow-y: scroll;
    max-height: 70vh;
    display: block;
    min-width: 450px !important;
}
 
#renewalTable {
    overflow-y: scroll;
    max-height: 70vh;
    display: block;
    min-width: 480px !important;
}
 
.notificationsContainer {
    /* Negative margin negates contentContainer margin & padding (cannot add width) */
    margin-left: -1em !important;
    width: 100vw !important;
}
 
.notification,
.welcomeMessage {
    border: 1px solid var(--color-uhc-blue) !important;
    background-color: white !important;
    border-radius: 124px !important;
    padding: 6px 24px 6px 16px !important;
}
 
.welcomeMessageNew {
    border: 1px solid var(--color-uhc-blue) !important;
    border-radius: 4px !important;
    width: 30vw !important;
    min-width: 400px !important;
}
 
.maxHeightButton {
    max-height: 36px;
}
 
.loggedInUserText {
    max-width: 220px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
 
 
/* Remove whitespace atop homepage background image when breadcrumbs are hidden */
 
#homepage {
    margin-top: -12px;
}
 
.scrollerImage {
    height: 338px;
}
 
.homepageContainer {
    margin-top: -12px;
    width: 100vw;
    margin-left: -12px;
}
 
.homepageContainer a.item {
    padding: 4px !important;
    height: 16px;
    min-width: 0px !important;
    width: 16px !important;
    border: 2px solid white !important;
    border-radius: 24px !important;
    margin: auto 6px !important;
}
 
.homepageContainer a.item:first-of-type,
.homepageContainer a.item:last-of-type {
    border: none !important;
    border-radius: 0 !important;
    background-color: rgba(0, 0, 0, 0) !important;
    height: 35px;
    color: white !important;
    font-size: 30px;
    padding-top: 0 !important;
}
 
.homepageContainer a.item:first-of-type {
    margin-right: 24px !important;
}
 
.homepageContainer a.item:last-of-type {
    margin-left: 24px !important;
}
 
.homepageContainer a.active.item {
    background-color: white !important;
}
 
@media screen and (min-width: 1400px) {
    .homepageContainer {
        min-height: 400px !important;
    }
}
 
div[disabled] {
    pointer-events: none;
    opacity: 0.6;
    filter: alpha(opacity=50);
    zoom: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    -moz-opacity: 0.5;
    -khtml-opacity: 0.5;
}
 
.genericPopup.ui.popup{
    z-index: 1001 !important;
 
}
 
.close-enrollment-button {
    width: max-content !important;
    margin-top: 5px !important;
}
 
.modelHeader {
    padding: 10px 0px 10px 15px;  
}
 
.modelContent {
    padding : 1.5em;
    display: block;
    width: 100%;
    line-height: 1.4;
}
 
.lightBlueButton {
    background-color: var(--color-uhc-bright-blue-20) !important;
    color: var(--color-uhc-blue) !important;
    border: 1px solid var(--color-uhc-blue) !important;
}
 
.chatbot-container{
    position: relative;
    height: 100%;
    width: 100%;
}

.chatbot-main{
    position: fixed;
    bottom: 103px;
    right: 40px;
}
 
.chatbot-app{
    display: flex;
    justify-content: right;
}
 
.chatbot-btn{
    position: fixed;
    right: 1px;
    bottom: 50px;
    z-index: 999;
    /* width: 4%;
    height: 4%; */
}
 
.react-chatbot-kit-chat-container {
    width: 400px;
}
 
.react-chatbot-kit-chat-bot-message {
    margin-left: 10px;
}
 
.react-chatbot-kit-chat-bot-message {
    background-color: var(--color-uhc-blue);
}
 
.react-chatbot-kit-chat-bot-message-arrow {
    border-right: 8px var(--color-uhc-bright-blue-20);
}
 
.react-chatbot-kit-chat-btn-send {
    background-color: var(--color-uhc-blue);
}
 
.ADE {
    background-color: var(--color-uhc-blue);
    width: 40px;
    height: 40px;
    display: grid;
    place-items: center;
    color: white;
    border-radius: 100%;
}
 
.start-btn {
    background-color: var(--color-uhc-blue);
    color: white;
    padding: .5rem 1rem;
    border-radius: 999px;
    border: none;
    cursor: pointer;
    margin-left: 3rem;
}
 
.slow-btn {
    margin-left: 1rem;
}
 
.img-component {
    margin-left: 3rem;
    position: relative;
}
 
.img-container {
    width: 185px;
    height: 185px;
    border: 2px solid var(--color-uhc-blue);
    overflow: hidden;
    border-radius: 5px;
}
 
.img-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
 
.img-component a {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 5px;
    background-color: var(--color-uhc-blue);
    color: white;
    text-decoration: none;
    padding: .5rem 1rem;
}
 
.img-component a:hover {
    background-color: var(--color-uhc-blue);
}

