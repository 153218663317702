.uhc-logo {
    position: absolute;
    top: 3rem;
    left: 6rem;
    width: 22rem;
}

.squares {
    position: absolute;
    top: 0;
    right: 6rem;
    width: 14rem;
}

.error-background {
    background-color: #f9fafb;
    min-height: 100vh;
}

.error-heading {
    color: var(--color-uhc-blue);
    font-family: Arial, sans-serif !important; 
    font-size:40px !important;
    text-align:center;
}

.error-paragraph {
    color: var(--color-uhc-blue-hover); 
    font-family: Arial, sans-serif !important; 
    text-align:center;
    font-size:16px !important;
}

.error-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.error-company-info-container {
    text-align: center;
    width: 400px;
    margin-top: 10px !important;
}

.returnToHomeButton {
    color: white !important;
    background-color: var(--color-uhc-blue) !important;
}

.returnToHomeButton:hover {
    background-color: var(--color-uhc-blue-hover) !important;
}