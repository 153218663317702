.negativeMarginBottom {
    margin-bottom: -20px !important;
}

.reviewCards {
    max-width: 90% !important;
    margin-left: 5% !important;
    box-shadow: 0 4px 10px -2px rgba(0, 0, 0, 0.4) !important;
}
.overFlowWrapClass{
    overflow-wrap: anyWhere;
}

.width20Percent {
    width: 20%;
}

.width11Percent {
    width: 11%;
}

.reviewCardItem {
    padding-right: 10px;
}

.borderRight {
    border-right: 1px solid lightgray;
}

.smallIcon {
    width: 30px;
    height: 30px;
}

.reviewCardInfoRow {
    margin-left: 50px;
}

#ownerAccordion {
    margin-left: 28px;
}


/* Removes padding from accordion when active */

#ownerAccordion .title {
    padding-top: 0;
}

#ownerAccordion .active {
    padding: 0px;
}

.ownerAccordionContent {
    margin-left: 23px;
    padding-top: 0px !important;
}

.ownerAccordionTitle {
    margin-left: 2px;
}


/* .ownerAccordionTitle .reviewCardItem.producerItem,
.ownerAccordionContent .reviewCardItem.producerItem{
    flex-basis: 250px;
}
.ownerAccordionTitle .reviewCardItem.agencyItem,
.ownerAccordionContent .reviewCardItem.agencyItem {
    flex-basis: 340px;
}

.ownerAccordionTitle .reviewCardItem.gaItem ,
.ownerAccordionContent .reviewCardItem.gaItem {
    flex-basis: 370px;
}

.ownerAccordionTitle .reviewCardItem.salesRepItem,
.ownerAccordionContent .reviewCardItem.salesRepItem{
    flex-basis: 340px;
} */

.multipleOwnerTitle:hover {
    background-color: #E5F7FB;
}

.sidebarReviewCard {
    width: 248px !important;
}

.collapseOption,
.expandOption {
    padding: 4px 8px;
    border-radius: 50px !important;
    max-width: 124px !important;
    margin-bottom: -28px !important;
}

.collapseOption:hover,
.expandOption:hover {
    background-color: #196ECF !important;
}