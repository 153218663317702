/* FEEDBACK SURVEY */
.feedbackIconPopup{
    -webkit-transform: rotate(270deg);
    position: relative;
    bottom: 480px !important;
    width: 8em;
    float: right;
    right: -35px;
    border: 2px solid var(--color-uhc-blue);
    border-radius: 0px !important;
    display: flex;
    padding: 7px;
    justify-content: space-evenly;
    color: #FFFFFF !important;
    z-index: 4;
    font-size: 14px !important;
    font-weight: 700 !important;
    cursor: pointer;
    background-color: var(--color-uhc-blue) !important ;
}

.feedbackIconPopupTop{
    -webkit-transform: rotate(270deg);
    position: fixed;
    top: 125px !important;
    width: 8em;
    float: right;
    right: -3em;
    border: 2px solid var(--color-uhc-blue);
    border-radius: 0px !important;
    display: flex;
    padding: 6px;
    justify-content: space-evenly;
    color: #FFFFFF !important;
    z-index: 4;
    font-size: 12px !important;
    font-weight: 700 !important;
    cursor: pointer;
    background-color: var(--color-uhc-blue) !important ;
}

.feedbackIconPopup:hover{
    right: -35px;
    background-color: #CCF2F7 !important;
    color: var(--color-uhc-blue) !important;
    -o-transition: right 0.5s ease-in-out; /* opera */
    -ms-transition: right 0.5s ease-in-out; /* IE 10 */
    -moz-transition: right 0.5s ease-in-out ; /* Firefox */
    -webkit-transition: right 0.5s ease-in-out; /*safari and chrome */
    transition: right 0.5s ease-in-out;
}

.feedbackIconPopupTop:hover{
    right: -3em;
    background-color: #CCF2F7 !important;
    color: var(--color-uhc-blue) !important;
    -o-transition: right 0.5s ease-in-out; /* opera */
    -ms-transition: right 0.5s ease-in-out; /* IE 10 */
    -moz-transition: right 0.5s ease-in-out ; /* Firefox */
    -webkit-transition: right 0.5s ease-in-out; /*safari and chrome */
    transition: right 0.5s ease-in-out;
}
@media (max-width: 100){
    .feedbackIconPopup {
        bottom: 75%;
    }
}

.ui.modal, .ui.active.modal .samFeedbackModal{
    width: 700px !important;
    max-width: 90% !important;
    padding-bottom: 30px !important;
}

.ui.modal, .ui.active.modal .samStartFeedback{
    width: 700px !important;
    max-width: 90% !important;
    padding-bottom: 0px !important;
}

#MMRModal{
    width:40% !important;
    min-width:278px !important;
}
 
.samFeedback.content{
    padding: 0px !important;
    padding-bottom: 20px !important;
}

.samFeedbackHeader{
    font-family: 'UHC Serif' !important;
    font-size: 28px !important;
    font-weight: 900 !important;
    padding-top: 30px !important;
    padding-left: 30px !important;
    margin-bottom: 8px !important;
}

.samFeedbackListeningHeader{
    font-family: 'UHC Serif' !important;
    font-size: 28px !important;
    font-weight: 900 !important;
    margin-bottom: 8px !important;
}

.samFeedback .ant-form-item {
    display: flex !important;
    flex-direction: column !important;
    align-items: left;
    margin: 0px !important;
    padding-top: 0.25em  !important;
    padding-bottom: 1em  !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
}

.samFeedback .ant-form-item:nth-of-type(3),
.samFeedback .ant-form-item:nth-of-type(5){
    padding-bottom: 0.5em  !important;
    background-color: #F6FEFF ;
}


/* LABEL */

.samFeedback .ant-form-item-label label{
    font-weight: 900 !important;
    font-family: var(--fontFamily-Regular) !important;
    font-size: 14px !important;
    float: left;
}
.samFeedback .ant-form-item-label > label::after{
    content: '';
    position: relative;
    top: -0.5px;
    margin: 0 8px 0 2px;
}

.samFeedback .ant-form-item:nth-of-type(3) .ant-form-item-label>label,
.samFeedback .ant-form-item:nth-of-type(5) .ant-form-item-label>label{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: fit-content;
    margin-top: 1em;
    margin-bottom: 0.5em;
}

.samFeedback .ant-form-item:nth-of-type(3) .ant-form-item-label:nth-child(1) ::after{
    white-space: pre;
    content: '(1 being Very difficult and 10 being Very easy)' !important;
    font-weight: 100 !important;
    font-size: 14px !important;
}

.samFeedback .ant-form-item:nth-of-type(5) .ant-form-item-label:nth-child(1) ::after{
    white-space: pre;
    content: '(0 being Not at all likely and 10 being Extremely likely)' !important;
    font-weight: 100 !important;
    font-size: 14px !important;
}


/* SLIDER */

.samFeedback .ant-slider-with-marks {
    width: 300px !important;
}

.samFeedback  .ant-slider-track :hover,
.samFeedback .ant-slider-rail :hover{
    cursor: pointer;
}

.samFeedback .ant-slider-rail,
.samFeedback .ant-slider-rail:focus,
.samFeedback .ant-slider-track,
.samFeedback .ant-slider-track:focus,
.samFeedback .ant-slider-track:active,
.samFeedback .ant-slider-handle,
.samFeedback .ant-slider-handle .ant-tooltip-open,
.samFeedback .ant-slider-handle .ant-tooltip-open .ant-slider-handle-click:hover,
.samFeedback .ant-slider-handle .ant-tooltip-open .ant-slider-handle-click:focus{
    visibility: hidden !important;
}

.samFeedback .ant-slider-dot{
    width: 22px;
    height: 22px;
    top: -10px;
    left: 0px;
    border-radius: 0px;
    border-width: 1px;
    border: 1px solid #5A5A5A !important;
}

.samFeedback .ant-slider-dot-active{
    width: 22px;
    height: 22px;
    font-size: 14px;
    border: transparent !important;
    background-color: var(--color-uhc-blue) !important;
}

.samFeedback .ant-slider-mark-text{
    top: -20px !important;
    text-align: center;
    padding-left: 1%;
    transform: translateX(0%) !important;
    color: #333333 !important;  
}
.samFeedback .ant-slider-mark > span:nth-of-type(11){
    padding-left: 0% !important;
}

.samFeedback .ant-slider-mark-text-active{
    color: white !important;
}


/* DROPDOWN */

.samFeedback .ant-select-dropdown-menu-item,
.samFeedback .ant-select-selection__placeholder,
.samFeedback .ant-select-selection-selected-value{
    width: 200px !important;
    font-size: 14px !important;
    font-family: var(--fontFamily-Regular) !important;
    cursor: pointer;
    color: #333333 !important;
}

.ant-select-selection__rendered{
    width: 200px !important;
    padding: 4px;
    font-size: 14px !important;
    border-radius: 2px;
    border: 1px solid #5A5A5A !important;
    color: #333333 !important;
}

.samFeedback .ant-form-item .ant-select, .ant-form-item .ant-cascader-pickern{
    width: 200px !important;
    color: #333333 !important;
}

.samFeedback .ant-select-selection .ant-select-arrow{
    color: #333333 !important;
    height: 8px !important;
    width: 12px !important;
}


/* INPUT */
.samFeedback .ant-input{
    width: 60%;
    padding: 2px;
    padding-left: 5px;
    font-size: 14px !important;
    border: 1px solid #5A5A5A !important;
}


/* BUTTON */

.samFeedback .ant-form-horizontal > div:nth-of-type(7){
    flex-direction: row-reverse !important;
    margin-top: 10px !important;
}

.samFeedback .ant-btn {
    background-color: var(--color-uhc-blue) !important;
    color: white !important;
    margin-left: 30px !important;
    border-radius: 50px !important;
    height: fit-content;
}

.samFeedback .ant-btn > span{
    font-size: 14px !important;
    font-weight: 900 !important;
    padding: 5px !important;
}