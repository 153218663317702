/***********************************************************************************************************************
*                                                                                                                      *
*                                  *--  Project X Global CSS Reference Guide  --*                                      *
*                                                                                                                      *
*                                                                                                                      *
*                   This Style Guide is intended for the use of Project X. The styles included derive                  *
*                    from the 2020 UHC Branding Guide: https://uhc-brand.netlify.app/design-with-care                  *
*                    and references to Optum Forms Design Guide: https://dpl.optum.com/web/forms.html                  *
*                                                                                                                      *
************************************************************************************************************************

*-- DESCRIPTION --*                 *-- TYPE --*        *-- VARIABLE --*                    *-- CLASSNAME/TAG --*

UHC Blue                            Color               --color-uhc-blue                    .backgroundColorBlue
UHC Blue Hover                      Color               --color-uhc-blue-hover              N/A
UHC Blue Hover                      Color               --color-uhc-blue-hover-text-border  N/A
UHC Bright Blue                     Color               --color-uhc-bright-blue             .backgroundColorBrightBlue
UHC Bright Blue 20                  Color               --color-uhc-bright-blue-20          .backgroundColorBrightBlue20
UHC Bright Blue 40                  Color               --color-uhc-bright-blue-40          .backgroundColorBrightBlue40
UHC Bright Blue 80                  Color               --color-uhc-bright-blue-80          .backgroundColorBrightBlue80
UHC Light Gray                      Color               --color-uhc-light-gray              .backgroundColorLightGray
UHC Medium Gray                     Color               --color-uhc-medium-gray             .backgroundColorMediumGray
Medical Plan Card Gold Metal        Color               --color-metal-gold                  .backgroundColorMetalGold
Medical Plan Card Gold Platinum     Color               --color-metal-platinum              .backgroundColorMetalPlatinum
Medical Plan Card Gold Bronze       Color               --color-metal-bronze                .backgroundColorMetalBronze
Medical Plan Card Gold Silver       Color               --color-metal-silver                .backgroundColorMetalSilver
White Background                    Color               N/A                                 .backgroundColorWhite

UHC Blue Font                       Font Color          --color-uhc-blue                    .blueFont
UHC Bright Blue Font                Font Color          --color-uhc-bright-blue             .brightBlueFont
Error/Important Red Font            Font Color          --color-flag-red                    .redFont
Warning Orange Font                 Font Color          --color-warning-orange              .orangeFont
Action Blue Font                    Font Color          --color-call-to-action              .actionFont
Psotive Green Font                  Font Color          --color-positive-green              .greenFont
Black Font                          Font Color          N/A                                 .blackFont
White Font                          Font Color          N/A                                 .whiteFont

UHC Blue                            Font                --color-uhc-blue                    .fontColorBlue
UHC Bright Blue                     Font                --color-uhc-bright-blue             .fontColorBrightBlue
UHC Bright Blue 20                  Font                --color-uhc-bright-blue-20          .fontColorBrightBlue20
UHC Bright Blue 40                  Font                --color-uhc-bright-blue-40          .fontColorBrightBlue40
UHC Light Gray                      Font                --color-uhc-light-gray              .fontColorLightGray
UHC Medium Gray                     Font                --color-uhc-medium-gray             .fontColorMediumGray

Header 1                            Font Size           N/A                                 h1
Header 2                            Font Size           N/A                                 h2
Header 3                            Font Size           N/A                                 h3
Header 4                            Font Size           N/A                                 h4
Header 5                            Font Size           N/A                                 h5
Header 6                            Font Size           N/A                                 h6
Paragraph                           Font Size           N/A                                 p

Justify Content Start               Flexbox             justify-content: flex-start;        .justifyStart
Justify Content Center              Flexbox             justify-content: center;            .justifyCenter
Justify Content Flex End            Flexbox             justify-content: flex-end;          .justifyEnd
Justify Content Space Between       Flexbox             justify-content: space-between;     .justifySpaceBetween
Justify Content Space Around        Flexbox             justify-content: space-around;      .justifySpaceAround
Align Items Start                   Flexbox             align-items: flex-start;            .alignStart
Align Items Center                  Flexbox             align-items: center;                .alignCenter
Align Items Baseline                Flexbox             align-items: baseline;              .alignBaseline
Align Items Flex End                Flexbox             align-items: flex-end;              .alignEnd
Flex Direction Row                  Flexbox             flex-direction: row;                .flexRow
Flex Direction Column               Flexbox             flex-direction: column;             .flexColumn
Flex Flow Row Wrap                  Flexbox             flex-flow: row wrap;                .flexFlowRowWrap
Flex Flow Row No Wrap               Flexbox             flex-flow: row nowrap;              .flexFlowRowNoWrap
Text Center                         Alignment           text-align: center;                 .textCenter
Text Left                           Alignment           text-align: left;                   .textLeft
Text Right                          Alignment           text-align: right;                  .textRight

Remove All Margins                  Margin              margin: 0;                          .noMargin
Remove Margin Top                   Margin              margin-top: 0;                      .noMarginTop
Remove Margin Bottom                Margin              margin-bottom: 0;                   .noMarginBottom
Remove Margin Left                  Margin              margin-left: 0;                     .noMarginLeft
Remove Margin Right                 Margin              margin-right: 0;                    .noMarginRight
Extra Small Margin Top              Margin              margin-top: 4px;                    .marginTopExtraSmall
Extra Small Margin Bottom           Margin              margin-bottom: 4px;                 .marginBottomExtraSmall
Extra Small Margin Left             Margin              margin-left: 4px;                   .marginLeftExtraSmall
Extra Small Margin Right            Margin              margin-right: 4px;                  .marginRightExtraSmall
Small Margin Top                    Margin              margin-top: 8px;                    .marginTopSmall
Small Margin Bottom                 Margin              margin-bottom: 8px;                 .marginBottomSmall
Small Margin Left                   Margin              margin-left: 8px;                   .marginLeftSmall
Small Margin Right                  Margin              margin-right: 8px;                  .marginRightSmall
Medium Margin Top                   Margin              margin-top: 12px;                   .marginTopMedium
Medium Margin Bottom                Margin              margin-bottom: 12px;                .marginBottomMedium
Medium Margin Left                  Margin              margin-left: 12px;                  .marginLeftMedium
Medium Margin Right                 Margin              margin-right: 12px;                 .marginRightMedium
Large Margin Top                    Margin              margin-top: 16px;                   .marginTopLarge
Large Margin Bottom                 Margin              margin-bottom: 16px;                .marginBottomLarge
Large Margin Left                   Margin              margin-left: 16px;                  .marginLeftLarge
Large Margin Right                  Margin              margin-right: 16px;                 .marginRightLarge
Extra Large Margin Top              Margin              margin-top: 20px;                   .marginTopExtraLarge
Extra Large Margin Bottom           Margin              margin-bottom: 20px;                .marginBottomExtraLarge
Extra Large Margin Left             Margin              margin-left: 20px;                  .marginLeftExtraLarge
Extra Large Margin Right            Margin              margin-right: 20px;                 .marginRightExtraLarge

Remove Padding Top                  Padding            padding-top: 0;                      .noPaddingTop
Remove Padding Bottom               Padding            padding-bottom: 0;                   .noPaddingBottom
Remove Padding Left                 Padding            padding-left: 0;                     .noPaddingLeft
Remove Padding Right                Padding            padding-right: 0;                    .noPaddingRight
Extra Small Padding Top             Padding            padding-top: 4px;                    .paddingTopExtraSmall
Extra Small Padding Bottom          Padding            padding-bottom: 4px;                 .paddingBottomExtraSmall
Extra Small Padding Left            Padding            padding-left: 4px;                   .paddingLeftExtraSmall
Extra Small Padding Right           Padding            padding-right: 4px;                  .paddingRightExtraSmall
Small Padding Top                   Padding            padding-top: 8px;                    .paddingTopSmall
Small Padding Bottom                Padding            padding-bottom: 8px;                 .paddingBottomSmall
Small Padding Left                  Padding            padding-left: 8px;                   .paddingLeftSmall
Small Padding Right                 Padding            padding-right: 8px;                  .paddingRightSmall
Medium Padding Top                  Padding            padding-top: 12px;                   .paddingTopMedium
Medium Padding Bottom               Padding            padding-bottom: 12px;                .paddingBottomMedium
Medium Padding Left                 Padding            padding-left: 12px;                  .paddingLeftMedium
Medium Padding Right                Padding            padding-right: 12px;                 .paddingRightMedium
Large Padding Top                   Padding            padding-top: 16px;                   .paddingTopLarge
Large Padding Bottom                Padding            padding-bottom: 16px;                .paddingBottomLarge
Large Padding Left                  Padding            padding-left: 16px;                  .paddingLeftLarge
Large Padding Right                 Padding            padding-right: 16px;                 .paddingRightLarge
Extra Large Padding Top             Padding            padding-top: 20px;                   .paddingTopExtraLarge
Extra Large Padding Bottom          Padding            padding-bottom: 20px;                .paddingBottomExtraLarge
Extra Large Padding Left            Padding            padding-left: 20px;                  .paddingLeftExtraLarge
Extra Large Padding Right           Padding            padding-right: 20px;                 .paddingRightExtraLarge


************************************************************************************************************************
*                                                                                                                      *
*                   For usages, coding conventions, and documentation, visit the following wiki page:                  *
*                  https://new-wiki.optum.com/pages/viewpage.action?spaceKey=ST&title=Global+CSS+Styles                *
*                                                                                                                      *
*                                                                                                                      *
************************************************************************************************************************/


:root {
    /* Colors */
    --color-uhc-blue: #002677; /* If this value is to ever change, see external references within ::pseudo elements */
    --color-uhc-blue-hover: #196ED0;
    --color-uhc-blue-hover-text-border: #1A6DD0;
    --color-uhc-bright-blue: #00BED5;
    --color-uhc-bright-blue-20: #CCF2F7;
    --color-uhc-bright-blue-40: #99E5EE;
    --color-uhc-bright-blue-80: #54CCDC;
    --color-uhc-light-gray: #F4F4F4;
    --color-uhc-medium-gray: #DADBDC;
    --color-call-to-action: #196ECF;
    --color-call-to-action-hover: rgba(25, 110, 207, .7);
    --color-high-impact-orange: #D14600;
    --color-high-impact-orange-hover: #A53400;

    /* Medical Plan Card Metals */
    --color-metal-gold: #FBBD09;
    --color-metal-platinum: #000000;
    --color-metal-bronze: #C38142;
    --color-metal-silver: #797979;

    /* Misc Colors */
    --color-flag-red: #9f3a38;
    --color-p-font: rgba(0, 0, 0, .87);
    --color-gray-font: #767676;
    --color-warning-orange: #F2711C;
    --color-warning-header: #794B03;
    --color-warning-p: #705730;
    --color-positive-green: #21BA45;
    --color-positive-green-hover: #16AB39;
    
    /* Font Sizes - @media Default Width */
    --fontSize-header: 24px;
    --fontSize-p: 12px;
    --fontSize-h1: 22px;
    --fontSize-h2: 18px;
    --fontSize-h3: 14px;
    --fontSize-h4: 12px;
    --fontSize-h5: 12px;
    --fontSize-h6: 12px;

    /* Font Sizes @media Width > 1200px */
    --fontSizeMedium-header: 28px;
    --fontSizeMedium-p: 14px;
    --fontSizeMedium-h1: 26px;
    --fontSizeMedium-h2: 20px;
    --fontSizeMedium-h3: 16px;
    --fontSizeMedium-h4: 14px;
    --fontSizeMedium-h5: 14px;
    --fontSizeMedium-h6: 12px;

    /* Font Families */
    --fontFamily-Regular: 'UHC Sans Regular Global Font';
    --fontFamily-Light: 'UHC Sans Light Global Font';
    --fontFamily-Bold: 'UHC Sans Bold Global Font';
    --fontFamily-Secondary: Arial;
    --fontFamily-Serif: 'UHC Sans Serif Global Font';
    --fontStyle-Secondary: sans-serif;

    /* Margin & Padding */
    --margin-padding-extra-small: 4px;
    --margin-padding-small: 8px;
    --margin-padding-medium: 12px;
    --margin-padding-large: 16px;
    --margin-padding-extra-large: 20px;
    --margin-padding-massive: 48px;
}


/* Margins and Padding with pre-defined sizes */
.marginAuto { margin: auto !important; }
/* Remove Margins & Padding */
.noMargin { margin: 0 !important; }
.noMarginTop { margin-top: 0 !important; }
.noMarginBottom { margin-bottom: 0 !important; }
.noMarginLeft { margin-left: 0 !important; }
.noMarginRight { margin-right: 0 !important; }
.noPaddingTop { padding-top: 0 !important; }
.noPaddingBottom { padding-bottom: 0 !important; }
.noPaddingLeft { padding-left: 0 !important; }
.noPaddingRight { padding-right: 0 !important; }
/* Extra Small Margins & Padding */
.marginTopExtraSmall { margin-top: var(--margin-padding-extra-small) !important; }
.marginBottomExtraSmall { margin-bottom: var(--margin-padding-extra-small) !important; }
.marginLeftExtraSmall { margin-left: var(--margin-padding-extra-small) !important; }
.marginRightExtraSmall { margin-right: var(--margin-padding-extra-small) !important; }
.paddingTopExtraSmall { padding-top: var(--margin-padding-extra-small) !important; }
.paddingBottomExtraSmall { padding-bottom: var(--margin-padding-extra-small) !important; }
.paddingLeftExtraSmall { padding-left: var(--margin-padding-extra-small) !important; }
.paddingRightExtraSmall { padding-right: var(--margin-padding-extra-small) !important; }
/* Small Margins & Padding */
.marginTopSmall { margin-top: var(--margin-padding-small) !important; }
.marginBottomSmall { margin-bottom: var(--margin-padding-small) !important; }
.marginLeftSmall { margin-left: var(--margin-padding-small) !important; }
.marginRightSmall { margin-right: var(--margin-padding-small) !important; }
.paddingTopSmall { padding-top: var(--margin-padding-small) !important; }
.paddingBottomSmall { padding-bottom: var(--margin-padding-small) !important; }
.paddingLeftSmall { padding-left: var(--margin-padding-small) !important; }
.paddingRightSmall { padding-right: var(--margin-padding-small) !important; }
/* Medium Margins & Padding */
.marginTopMedium { margin-top: var(--margin-padding-medium) !important; }
.marginBottomMedium { margin-bottom: var(--margin-padding-medium) !important; }
.marginLeftMedium { margin-left: var(--margin-padding-medium) !important; }
.marginRightMedium { margin-right: var(--margin-padding-medium) !important; }
.paddingTopMedium { padding-top: var(--margin-padding-medium) !important; }
.paddingBottomMedium { padding-bottom: var(--margin-padding-medium) !important; }
.paddingLeftMedium { padding-left: var(--margin-padding-medium) !important; }
.paddingRightMedium { padding-right: var(--margin-padding-medium) !important; }
/* Large Margins & Padding */
.marginTopLarge { margin-top: var(--margin-padding-large) !important; }
.marginBottomLarge { margin-bottom: var(--margin-padding-large) !important; }
.marginLeftLarge { margin-left: var(--margin-padding-large) !important; }
.marginRightLarge { margin-right: var(--margin-padding-large) !important; }
.paddingTopLarge { padding-top: var(--margin-padding-large) !important; }
.paddingBottomLarge { padding-bottom: var(--margin-padding-large) !important; }
.paddingLeftLarge { padding-left: var(--margin-padding-large) !important; }
.paddingRightLarge { padding-right: var(--margin-padding-large) !important; }
/* Extra Large Margins & Padding */
.marginTopExtraLarge {margin-top: var(--margin-padding-extra-large) !important; }
.marginBottomExtraLarge {margin-bottom: var(--margin-padding-extra-large) !important; }
.marginLeftExtraLarge {margin-left: var(--margin-padding-extra-large) !important; }
.marginRightExtraLarge {margin-right: var(--margin-padding-extra-large) !important; }
.paddingTopExtraLarge {padding-top: var(--margin-padding-extra-large) !important; }
.paddingBottomExtraLarge {padding-bottom: var(--margin-padding-extra-large) !important; }
.paddingLeftExtraLarge {padding-left: var(--margin-padding-extra-large) !important; }
.paddingRightExtraLarge {padding-right: var(--margin-padding-extra-large) !important; }
/* Massive Margins & Padding */
.marginTopMassive {margin-top: var(--margin-padding-massive) !important; }
.marginBottomMassive {margin-bottom: var(--margin-padding-massive) !important; }
.marginLeftMassive {margin-left: var(--margin-padding-massive) !important; }
.marginRightMassive {margin-right: var(--margin-padding-massive) !important; }
.paddingTopMassive {padding-top: var(--margin-padding-massive) !important; }
.paddingBottomMassive {padding-bottom: var(--margin-padding-massive) !important; }
.paddingLeftMassive {padding-left: var(--margin-padding-massive) !important; }
.paddingRightMassive {padding-right: var(--margin-padding-massive) !important; }



/* Background colors */
.backgroundColorBlue {background-color: var(--color-uhc-blue) !important; }
.backgroundColorBrightBlue {background-color: var(--color-uhc-bright-blue) !important; }
.backgroundColorBrightBlue20 {background-color: var(--color-uhc-bright-blue-20) !important; }
.backgroundColorBrightBlue40 {background-color: var(--color-uhc-bright-blue-40) !important; }
.backgroundColorBrightBlue80 {background-color: var(--color-uhc-bright-blue-80) !important; }
.backgroundColorLightGray {background-color: var(--color-uhc-light-gray) !important; }
.backgroundColorMediumGray {background-color: var(--color-uhc-medium-gray) !important; }
/* Medical Plan Metals */
.backgroundColorMetalGold {background-color: var(--color-metal-gold) !important; }
.backgroundColorMetalPlatinum {background-color: var(--color-metal-platinum) !important; }
.backgroundColorMetalBronze {background-color: var(--color-metal-bronze) !important; }
.backgroundColorMetalSilver {background-color: var(--color-metal-silver) !important; }
.backgroundColorWhite {background-color: white !important;}



/* Flexbox & Text Alignment */
.justifyStart { display: flex; justify-content: flex-start; }
.justifyCenter { display: flex !important; justify-content: center; }
.justifyEnd { display: flex; justify-content: flex-end !important; }
.justifySpaceBetween { display: flex; justify-content: space-between; }
.justifySpaceAround { display: flex; justify-content: space-around; }
.alignStart { display: flex; align-items: flex-start; }
.alignCenter { display: flex; align-items: center; }
.alignBaseline { display: flex; align-items: baseline; }
.alignEnd { display: flex; align-items: flex-end; }
.flexRow { display: flex; flex-direction: row; }
.flexColumn { display: flex; flex-direction: column; }
.flexFlowRowNoWrap { display: flex; flex-flow: row nowrap; }
.flexFlowRowWrap { display: flex; flex-flow: row wrap; }
.displayInline {display: inline;}
.textCenter { text-align: center !important; }
.textLeft { text-align: left; }
.textRight { text-align: right; }
.floatRight { float: right }
.floatLeft { float: left }
.overflowScroll { overflow: scroll !important; }
.overflowScrollX { overflow-x: scroll !important; overflow-y: hidden !important; }
.overflowScrollY { overflow-y: scroll !important; overflow-x: hidden !important; }



/* Default Font Families and Sizes */
.redAsterisk { font-size: 20px !important; }
.font18 { font-size: 18px !important; }

p, input, span, div, th, td, button, b, button.small { font-size: var(--fontSize-p) !important; }
h1 { font-size: var(--fontSize-h1) !important; }
h2 { font-size: var(--fontSize-h2) !important; }
h3 { font-size: var(--fontSize-h3) !important; }
h4 { font-size: var(--fontSize-h4) !important; }
h5 { font-size: var(--fontSize-h5) !important; }
h6 { font-size: var(--fontSize-h6) !important; }
header {font-size: var(--fontSize-header) !important; }

/* Font sizes should increase at 1400px @media width */
@media screen and (min-width: 1400px) {
    /* TODO: May make use of this variable in the future */
    p, input, span, div, th, td, button, b { font-size: var(--fontSizeMedium-p) !important; }
    h1 { font-size: var(--fontSizeMedium-h1) !important; }
    h2 { font-size: var(--fontSizeMedium-h2) !important; }
    h3 { font-size: var(--fontSizeMedium-h3) !important; }
    h4 { font-size: var(--fontSizeMedium-h4) !important; }
    h5 { font-size: var(--fontSizeMedium-h5) !important; }
    h6 { font-size: var(--fontSizeMedium-h6) !important; }
    header {font-size: var(--fontSizeMedium-header) !important; }
}

p, h1, h2, h3, h4, h5, h6, a, label {
    font-family: Arial, sans-serif; /* Font Fallback */
    color: var(--color-p-font);
}
p {
    font-family: var(--fontFamily-Regular) !important;
}
h1 {
    font-family: var(--fontFamily-Regular) !important;
}
h2 {
    font-family: var(--fontFamily-Light) !important;
}
h3 {
    font-family: var(--fontFamily-Regular) !important;
}
h4 {
    font-family: var(--fontFamily-Bold) !important;
}
h5 {
    font-family: var(--fontFamily-Regular) !important;
}
h6 {
    font-family: var(--fontFamily-Bold) !important;
}
a {
    transition: color 0.3s cubic-bezier(0.23, 1, 0.32, 1) 0s;
    -webkit-tap-highlight-color: transparent;
    text-decoration: underline;
    font-weight: 700;
    /* Can be overwritten to --color-call-to-action with !important flag */
    color: var(--color-uhc-blue);
}
pre{
    white-space : pre-wrap
}
a:hover {
    /* Can be overwritten to --color-call-to-action-hover with !important flag */
    color: var(--color-uhc-blue-hover) !important;
}
.extraSmallFont{
    font-size: 10px !important;
}
.small, .smallFont {
    font-size: 12px !important;
}
.mediumFont {
    font-size: 14px !important;
}
.largeFont {
    font-size: 16px !important;
}
.largerFont {
    font-size: 20px !important;
}
.massiveFont {
    font-size: 36px !important;
}
.strongFont{
    font-weight: 900 !important;
}
/* TODO: button, a, input, span, div, th, td, small, strong, label fonts */
strong {
    font-weight: 900 !important;
}
button, a, input, span, div, th, td, small {
    font-family: var(--fontFamily-Regular) !important;
    font-weight: 400 !important;
}
i.icon.circle {
    min-height: 14px !important;
    min-width: 14px !important;
}
i.blue.icon.circle {
    color: var(--color-uhc-blue) !important;
}
i.warning {
    color: var(--color-flag-red) !important;
}
/* UHC blue used for Favorite icon on plan cards/filtering */
i.icon.blue {
    color: var(--color-uhc-blue) !important;
}
.serifFont {
    font-family: var(--fontFamily-Serif) !important;
}
/* Text input field placehold text meets accessibility standards */
input::-webkit-input-placeholder {
    color: black !important;
    font-weight: 400;
    opacity: 0.7;
}    
input:-moz-placeholder {
    color: black !important;
    font-weight: 400;
    opacity: 0.7;
}    
input::-moz-placeholder {
    color: black !important;
    font-weight: 400;
    opacity: 0.7;
} 
input:-ms-input-placeholder {  
    color: black !important;
    font-weight: 400;
    opacity: 0.7;
}
.default.text {
    color: black !important;
    font-weight: 400;
    opacity: 0.7;
}



/* UHC Branded Font colors */
.blueFont { color: var(--color-uhc-blue) !important; }
.brightBlueFont { color: var(--color-uhc-bright-blue) !important; }
.actionBlueFont { color: var(--color-call-to-action) !important; }
/* Interaction Colors */
.redFont { color: var(--color-flag-red) !important; }
.orangeFont { color: var(--color-warning-orange) !important; }
.actionFont { color: var(--color-call-to-action) !important; }
.greenFont { color: var(--color-positive-green) !important; }
.blackFont { color: black !important; }
.grayFont { color: var(--color-gray-font) !important; }
.whiteFont { color: white !important; }
/* Medical Plan Metals */
.fontColorMetalGold { color: var(--color-metal-gold) !important; }
.fontColorMetalPlatinum { color: var(--color-metal-platinum) !important; }
.fontColorMetalBronze { color: var(--color-metal-bronze) !important; }
.fontColorMetalSilver { color: var(--color-metal-silver) !important; }
/* Psuedo elements cannot use css vars and must be hardcoded */
/* See https://caniuse.com/#search=css%20var */
.actionFont:hover { color: #002677 !important; cursor: pointer !important; }
/* Semantic UI Field Error font color */
.error.field {
    color: var(--color-flag-red) !important;
}
/* Required fields that do not contain an error */
.redAsterisk {
    color: red !important;
}



/* Buttons */
.ui.button.brightBlueButton {
    background-color: rgba(204, 242, 247, 0.3) !important;
}
.ui.button.brightBlueButton:hover {
    background-color: rgba(204, 242, 247, 0.5) !important;
}
.ui.button.actionButton {
    background-color: var(--color-call-to-action) !important;
    color: white !important;
}
.ui.button.actionButton:hover {
    background-color: #002677 !important;
}
.ui.button.orangeButton {
    background-color: var(--color-high-impact-orange) !important;
    color: white !important;
}
.ui.button.orangeButton:hover {
    background-color: #A53400 !important;
    color: white !important;
}
.ui.button.whiteButton, .ui.button.basic, .ant-btn {
    background-color: white !important;
    color: var(--color-uhc-blue) !important;
    border: 1px solid var(--color-uhc-blue) !important;
}
.ui.button.whiteButton:hover, .ui.button.basic:hover, .ant-btn:hover {
    color: var(--color-uhc-blue-hover-text-border) !important;
    border: 1px solid var(--color-uhc-blue-hover-text-border) !important;
}
.ui.button.primary, .ui.primary.button, .ui.button.blue {
    background-color: var(--color-uhc-blue) !important;
    border: none !important;
    color: white !important;
}
.ui.button.primary:hover, .ui.button.blue:hover {
    background-color: var(--color-uhc-blue-hover) !important;
    border: none !important;
    color: white !important;
}
.ui.button.positive {
    background-color: var(--color-positive-green) !important;
    border: 1px solid var(--color-positive-green) !important;
    color: white;
}
.ui.button.positive:hover {
    background-color: var(--color-positive-green-hover) !important;
    border: 1px solid var(--color-positive-green-hover) !important;
}
.ui.button, .ReactTable .pagination-bottom .-btn {
    background-color: var(--color-uhc-medium-gray);
    border: 1px solid var(--color-uhc-medium-gray);
    border-radius: 21px !important;
}
/* Dashboard Buttons */
.ui.button.massiveButton {
    width: 22% !important;
    border-radius: 4px !important;
    color: white !important;
    background-color: var(--color-uhc-blue) !important;
    height: 64px !important;
    font-size: 24px !important;
    border-radius: 64px !important;
    display: inline-flex !important;
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
}
.ui.button.massiveButton.whiteButton {
    background-color: white !important;
    color: var(--color-uhc-blue) !important;
}



/* Misc Enhancements */
.maxWidthContainer {
    max-width: 1000px;
    margin: 0 auto;
    padding: 12px 50px 20px;
}
.width100Percent {
    width: 100% !important;
}
.boxShadowSmall {
    box-shadow: 0 4px 2px -2px rgba(0,0,0,0.4) !important;
}
.boxShadowLarge {
    box-shadow: 0 4px 12px -2px rgba(0,0,0,0.4) !important;
}
.borderRadiusSmall {
    border-radius: 4px !important;
}
.borderRadiusLarge {
    border-radius: 48px !important;
}



/* Component-Specific Styles */
.ui.warning.message {
    background-color: #FFFAF3 !important
}
.ui.message .header {
    font-family: var(--fontFamily-Regular) !important;
    /* font-size: var(--fontSize-h5) !important; */
    font-weight: bold !important;
}
.ui.message p {
    font-family: var(--fontFamily-Regular) !important;
    /* font-size: var(--fontSize-h6) !important; */
}
.ui.warning.message .header, .ui.warning.message p {
    color: #705730;
}
.ui.message {
    background-color: var(--color-uhc-light-gray) !important;
}
.ui.blue.segment:not(.inverted) {
    border-top: 2px solid var(--color-uhc-blue) !important;
}
.ui.modal > .header {
    background-color: var(--color-uhc-blue) !important;
}
.ui.modal > .header h1, .ui.modal > .header h2, .ui.modal > .header h3, .ui.modal > .header h4
.ui.modal > h1.header, .ui.modal > h2.header, .ui.modal > h3.header, .ui.modal > h4.header {
    color: white !important;
}
.ui.modal > .close.icon {
    color: white !important;
}
.ui.compact.secondary.segment {
    background-color: var(--color-uhc-light-gray) !important;
}
.ant-tabs-tab-active {
    color: var(--color-uhc-blue) !important;
}
.MuiCheckbox-colorPrimary.Mui-checked, .ant-menu-submenu-title {
    color: var(--color-uhc-blue) !important;
}
.ant-menu-submenu-title:hover {
    color: var(--color-uhc-blue-hover) !important;
}
.ant-menu-item-selected {
    background-color: var(--color-uhc-bright-blue-20) !important;
}
/* Psuedo elements cannot use css vars and must be hardcoded */
/* See https://caniuse.com/#search=css%20var */
.ant-menu-item-selected .ant-menu-item ::after {
    border-right: 3px solid #002677 !important;
}
.pieDesign {
    border: 2px solid var(--color-uhc-blue) !important;
    border-radius: 3px !important;
    width: 705px !important;   
    margin-top: 3px !important;
    box-shadow: 0 4px 2px -2px rgba(0,0,0,0.4) !important;
}
/* ReactTable body X-axis overflow to prevent multiple dynamic panels */
.ReactTable .rt-tbody {
    overflow-x: hidden !important;
}
.barStatus {
    height: 185px !important;
    background-color: #FFFFFF !important;
}
.cursorPointer {
    cursor: pointer !important;
}
/* Product Selection Tab Font NB/RN */
.productSelectionTabs .ui.massive.pointing.secondary.menu .item {
    font-family: var(--fontFamily-Serif) !important;
}

/* Arrow animation styles for page direction */
.loaderContainer {
    height: 180px;
    width: 250px;
    padding-left: 36px;
}

.redirectArrowContainer {
    width: 100%;
    height: 100%;
}

.redirectArrow {
    background-image: url('../../../images/redirectArrow.png');
    animation: redirectArrow 1s;
    max-height: 225px;
}

.slideInLeft {
    max-height: 225px;
    animation: slideInLeft 2.2s infinite;
}

@keyframes slideInLeft {
    0% {
        -webkit-transform: translateX(-2000px);
        transform: translateX(-2000px);
    }
    40%, 60% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    100% {
        -webkit-transform: translateX(2000px);
        transform: translateX(2000px);
    }
}

@keyframes redirectArrow {
    0% {
        opacity: 0.7;
        -webkit-transform: translateX(-2000px) scale(.7);
        transform: translateX(-2000px) scale(.7);
    }
    80% {
        opacity: .7;
        -webkit-transform: translateX(0) scale(.7);
        transform: translateX(0) scale(.7);
    }
    100% {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

/* Font imports */
@font-face {
    font-family: 'UHC Sans Light Global Font';
    font-style: normal;
    font-weight: 300;
    src: url("fonts/uhcsans-regular-webfont.woff");
    /* IE9 Compat Modes */
    /* src: url("/fonts/uhcsans-regular-webfont.eot?#iefix") format("embedded-opentype"), url("/fonts/uhcsans-regular-webfont.woff2") format("woff2"), url("/uhc/uhcsans-regular-webfont.woff") format("woff"), url("/fonts/uhcsans-regular-webfont.ttf") format("truetype"), url("/fonts/uhcsans-regular-webfont.svg#uhc_sansregular") format("svg"); */
}

@font-face {
    font-family: 'UHC Sans Regular Global Font';
    font-style: normal;
    font-weight: 400;
    src: url("fonts/uhcsans-medium-webfont.woff");
    /* IE9 Compat Modes */
    /* src: url("/fonts/uhcsans-medium-webfont.eot?#iefix") format("embedded-opentype"), url("/fonts/uhcsans-medium-webfont.woff2") format("woff2"), url("/fonts/uhcsans-medium-webfont.woff") format("woff"), url("/fonts/uhcsans-medium-webfont.ttf") format("truetype"), url("/fonts/uhcsans-medium-webfont.svg#uhc_sansmedium") format("svg"); */
}

@font-face {
    font-family: 'UHC Sans Bold Global Font';
    font-style: normal;
    font-weight: 700;
    src: url("fonts/uhcsans-bold-webfont.woff");
    /* IE9 Compat Modes */
    /* src: url("/fonts/uhcsans-bold-webfont.eot?#iefix") format("embedded-opentype"), url("/fonts/uhcsans-bold-webfont.woff2") format("woff2"), url("/fonts/uhcsans-bold-webfont.woff") format("woff"), url("/fonts/uhcsans-bold-webfont.ttf") format("truetype"), url("/fonts/uhcsans-bold-webfont.svg#uhc_sansbold") format("svg"); */
}

@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url("fonts/MaterialIcons-Regular.eot");
    /* IE9 Compat Modes */
    /* src: url("/fonts/MaterialIcons-Regular.eot?#iefix") format("embedded-opentype"), url("/fonts/MaterialIcons-Regular.woff") format("woff"), url("/fonts/MaterialIcons-Regular.ttf") format("truetype"); */
}

@font-face {
    font-family: 'UHC Sans Serif Global Font';
    font-style: bold;
    font-weight: 600;
    src: url("fonts/UHCSerifHeadline-Semibold.woff");
    /* Insert IE9 Compat Modes Here */
}
