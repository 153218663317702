.activeDashboard {
    color: var(--color-uhc-blue) !important;
}

.stepper {
    top: -30px !important;
    margin-bottom: 0em !important;
}

.renewalStepper-row1 {
    top: 5px !important;
    padding: 0px !important;
    font-weight: bold !important;
}

.renewalStepper {
    top: -13px !important;
    padding: 0px !important;
    height: 10px !important;
    left: -10px !important;
}

.renewalStepper .ui.breadcrumb {
    background-color: #F9FAFB !important;
    font-size: 14px !important;
}

.check-icon-hidden {
    opacity: 0;
}

.check-icon-show {
    color: #1EAC1B !important;
    font-weight: bold !important;
}

.completed {
    margin-right: .5em !important;
    border-radius: 50% !important;
    font-weight: 800 !important;
}

.active .ui.rounded {
    border: 2px solid var(--color-uhc-bright-blue) !important
}

.rounded {
    border-radius: 50% !important;
}


.ui.circular.label.activeCircleRen{
    background-color: var(--color-uhc-blue) !important;
    border: 1px solid var(--color-uhc-bright-blue) !important;
    color: white;
}

.ui.circular.label.beenToCircleRen{
    background-color: var(--color-uhc-bright-blue-20) !important;
    color: var(--color-uhc-blue) !important;
    border: 1px solid var(--color-uhc-bright-blue) !important;
}