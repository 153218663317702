body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app-container {
  position: relative;
  min-height: 100vh;
  background-color: rgb(249, 250, 251) !important;
  padding-bottom: 48px;
}

.ui.header.white {
  color: white;
}

.calendar-input {
  margin: 15px 0 0 !important;
}

/* The slider itself */
.qqSlider {
  -webkit-appearance: none;  /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 20px; /* Specified height */
  background: #d3d3d3; /* Grey background */
  outline: none; /* Remove outline */
  opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: .2s; /* 0.2 seconds transition on hover */
  transition: opacity .2s;
}

/* Mouse-over effects */
.qqSlider:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.qqSlider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 8px; /* Set a specific slider handle width */
  height: 20px; /* Slider handle height */
  background: #003C71; /* Green background */
  cursor: pointer; /* Cursor on hover */
}

.qqSlider::-moz-range-thumb {
  width: 8px; /* Set a specific slider handle width */
  height: 20px; /* Slider handle height */
  background: #4CAF50; /* Green background */
  cursor: pointer; /* Cursor on hover */
}

.paddedCheckbox {
  padding: 5px;
}

.error {
  color: red;
}

.ui.modal,
.ui.active.modal {
  position: static !important;
}